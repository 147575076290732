import axios from "axios";
import { ASSET_AUTH_API } from "../../config/default.config";

const getLoginToken = async (params: URLSearchParams) => {

  const response = await axios.post(
    ASSET_AUTH_API.GET_LOGIN_TOKEN, params
  )
  return response;
};

const validateToken = async (params: URLSearchParams) => {

  const response = await axios.post(
    ASSET_AUTH_API.VERIFY_LOGIN_TOKEN,
    params,
    {
       headers : {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
  );
  return response;
};

const logoutToken = async (params: URLSearchParams) => {

  const response = await axios.post(
    ASSET_AUTH_API.LOGOUT_TOKEN,
    params,
    {
       headers : {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
  );
  return response;
};

  export const assetKeycloakService = { 
    getLoginToken,
    validateToken,
    logoutToken
  };